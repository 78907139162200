$header-height: 100px;

.ugh-header {
    background:#181818;
    height:$header-height;
    margin-bottom:30px;

    .row {
        align-items: center;
    }
}

.ugh-header-cred {
    display: flex;
    align-items: center;
    height: $header-height;
}

.ugh-header-title {
    color:#fff;
    font-size:25px;
    text-transform: uppercase;
}

.ugh-header-langselect {
    float: right;
    color: #fff;

    label {
        margin-right:10px;
    }

    select {
        text-transform:uppercase;
        width:50px;
        outline: none;
        cursor: pointer;
    }
}

.ugh-spinner {
    display: flex;
    width: 200px;
    margin: auto;
    margin-top: 200px;
    align-items: center;

    .ugh-spinner-title {
        padding-left: 20px;
    }
}

.ugh-message-box {
    padding: 20px;
    border: 1px solid #ee8383;
    border-radius: 10px;
    background: #ffeeee;
    font-weight: bold;
    color: #ee8383;
}

.ugh-section-footer {
    margin-top:20px;
    margin-bottom:20px;
    text-align: right;
    font-size:12px;
}

/** game details **/

.output-cycle-details {
    font-size:14px;

    h2 {
        font-size:20px;
        color:#0086cd;
        margin-bottom:30px;
    }

}

.cycle-details--item {
    display:flex;
}

.cycle-details--label {
    min-width: 140px;
}

.cycle-details--item-desc {
    padding:10px 0px 20px 10px
}

.cycle-details--item-desc span {
    padding-left:10px;
    color:red;
    font-style:italic
}

/** game actions **/


.output-cycle-actions--item {
    display:flex;
    border-bottom: 1px solid #cecece;

    > div {
        flex-basis: auto;
        padding: 20px;
    }
}

.cycle-col-index {
    width:40px;
}

.cycle-col-text {
    width:330px;
}

.cycle-col-symbols {
    width:650px;
}

.cycle-symbols {

}

.cycle-symbols-grid--container {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
}

.cycle-symbols-grid--col div {
    text-align: center;
    line-height: 30px;
}

.cycle-symbols-grid-row {
    padding: 1px;
    box-sizing: border-box;
}

.cycle-symbols-grid {
    //transform: scale(0.8);
   // margin: 0 auto calc(-450px * (1 - 0.8));

   display: inline-block;
   box-sizing: border-box;
   position: relative;
   transform-origin: 0% 0;
  
   width: 750px;
}

@media  (max-width: 992px) {
    .cycle-symbols-grid {
        transform: scale(0.5);
        margin: 0 auto calc(-450px * (1 - 0.5));
        //width: 450px;
    }
    .row_size_3 {
        height: 450px;
    }

    .cycle-col-symbols {
        padding-top: 0px !important;
    }
}

@media  (min-width: 992px) {
    .cycle-symbols-grid {
        transform: scale(0.8);
        margin: 0 auto calc(-450px * (1 - 0.8));
        //width: 750px;
    }

    .row_size_3 {
        height: 457px;
    }
}


@media  (max-width: 1000px) {
    .details-section {
        margin-bottom:20px;

        h2 {
            margin-bottom: 10px;
        }
    }
}


.row_size_4 {
    height: 606px;
}

.cycle-symbols-winline {
    position: absolute;
    top:4px;
    left:4px;
    z-index: 10;

    img {
    }
}

.cycle-symbols-reels {
    display:flex;
    position: absolute;
    top:0px;
    left:0px;
    z-index: 0;

    &:before  {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 50;
        border: 3px solid #000000;
        border-radius: 10px;
    }
}
.cycle-symbols-grid--row {
   
    div {
        text-align:center;
        //width:30px;
        //height:30px;
        line-height: 30px;

        //margin: 1px;

    }
}

.cycle-symbols-grid-col {
    padding: 1px;
    box-sizing: border-box;
}

.cycle-symbols-grid-image {
    background:#cecece;
    border-radius: 2px;
    border-radius: 10px;
    overflow: hidden;

    img {
       // width:100%;
    }
}

/** games actions **/

.gameActions-listItem {
    display:flex;
    padding: 20px 0px 20px 0px;
    border-bottom:1px solid #cecece
}

.gameActions-itemCol {
    padding-right:20px;
}

.gameActions-itemCol:nth-child(1) {
    width: 140px;
}
.gameActions-itemCol:nth-child(2) {
    width: auto;
    min-width: 200px;
}

.gameActions-itemCol:nth-child(3) {
    width: 250px;
}

.symbolsGrid {
    display:flex;
    margin-top:40px;
}

.symbolsGrid--col {
    width:30px;
}

.symbolsGrid--item {
    width:30px;
    line-height: 30px;
    text-align:center;
    background:#cecece;
    border:1px solid #fff;
}

.cycle-winlines-details {

    span {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        margin: 5px;
        border: 1px solid #cecece;
        line-height: 30px;
        border-radius: 2px;
        cursor: pointer;
        font-weight: bold;
    }
  
    span:hover {
        background: blue;
        color: #fff;
    }
  }